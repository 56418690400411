<template>
  <div>
    <!-- 密码验证 -->
    <div v-if="isPassword"
         class="salarySiginPassword">
      <van-nav-bar :title="$t('salarySiginPassword.sspTxt1')"
                   left-text
                   left-arrow
                   @click-left="onClickLeft"
                   @click-right="onClickRight"
                   :fixed="true" />
      <div class="fixedH"
           style="height: 1.22667rem"></div>
      <div class="content">
        <div class="inputPassword">{{ $t("salarySiginPassword.sspTxt2") }}</div>

        <div v-if="use_salary_pwd_mix"
             class="inputPasswordGrid"
             style="margin: 0 20px;">
          <van-field v-if="viewPwd"
                     v-model="value"
                     type="password"
                     placeholder="请输入密码">
            <template #right-icon>
              <van-icon name="eye-o"
                        @click="viewPwd=!viewPwd" />
            </template>
          </van-field>
          <van-field v-else
                     v-model="value"
                     placeholder="请输入密码">
            <template #right-icon>
              <van-icon name="eye-o"
                        @click="viewPwd=!viewPwd" />
            </template>
          </van-field>
        </div>
        <div v-else
             class="inputPasswordGrid">
          <van-password-input :value="value"
                              :gutter="10"
                              :focused="showKeyboard"
                              :error-info="errorInfo"
                              @focus="showKeyboard = true" />
          <van-number-keyboard v-model="value"
                               extra-key="X"
                               :show="showKeyboard"
                               @blur="showKeyboard = false" />
        </div>
        <div class="inputPasswordDesc">{{ $t("salarySiginPassword.sspTxt16") }}</div>
        <div class="hasBtn"
             v-if="use_salary_pwd_mix"
             style="position: absolute;bottom: 20px;left: 20px;right: 20px;">
          <van-button type="info"
                      block
                      :disabled="!value"
                      @click="checkVal">{{$t("module.confirm")}}</van-button>
        </div>
      </div>
    </div>
    <!-- 短信验证 -->
    <div v-else
         class="smsVerification_dia">
      <van-nav-bar :title="$t('salarySiginPassword.sspTxt4')"
                   left-text
                   left-arrow
                   @click-left="onClickLeft"
                   @click-right="onClickRight"
                   :fixed="true" />
      <div class="fixedH"
           style="height: 1.22667rem"></div>
      <div class="content">
        <van-field :label="$t('salarySiginPassword.sspTxt5')"
                   v-model="iphone"
                   readonly />
        <van-field v-model="code"
                   center
                   clearable
                   type="tel"
                   :label="$t('salarySiginPassword.sspTxt6')"
                   :placeholder="$t('salarySiginPassword.sspTxt7')">
          <template #button>
            <van-button size="small"
                        type="primary"
                        disabled
                        v-if="!showGetCode || countDown !== 0">{{ countDown === 0 ? $t('salarySiginPassword.sspTxt8') : `${countDown}S${$t('salarySiginPassword.sspTxt9')}` }}</van-button>
            <van-button size="small"
                        type="primary"
                        v-if="showGetCode && countDown === 0"
                        @click="getCode">{{$t('salarySiginPassword.sspTxt8')}}</van-button>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round
                      block
                      type="info"
                      :disabled="!canSave"
                      @click="submit">{{$t('module.confirm')}}</van-button>
        </div>
      </div>
    </div>
    <!-- // 协议弹窗 -->
    <van-popup v-model="showAgreePop"
               class="salaryAgreePop"
               round
               :close-on-click-overlay="false">
      <div class="title">
        {{$t('salarySiginPassword.sspTxt10')}}
      </div>
      <div class="content">
        <iframe id="iframe"
                class="pay_agree_iframe"
                :src="pay_agreement_url"
                style="height:calc(100% - 20px); width: 100%;margin-top: 15px; "
                frameborder="0"></iframe>
      </div>
      <div class="footer">
        <div class="arg1"
             @click="agree">{{PrivacyTimeOut>0?`${$t('salarySiginPassword.sspTxt11')}（${PrivacyTimeOut}S）`:$t('salarySiginPassword.sspTxt11')}}</div>
        <div class="arg2"
             @click="notAgree">{{$t('salarySiginPassword.sspTxt12')}}</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { checkSalaryPwd, getSalarySignParam, agreePaySignProtocol } from "@api/wx.js";
import { getSmsCode } from '@api/login.js'
import { Dialog, Toast, Notify } from "vant";
export default {
  data () {
    return {
      userInfo,
      showKeyboard: false,
      errorInfo: "",
      value: "",
      leftToPath: "",
      checkmode: 1,
      firstchangepwd: false,
      isagree: false,
      isfirstlogin: false,
      nosign: false,
      pay_agreement_timeout: 5,
      pay_agreement_url: "",
      use_pay_agreement: false,
      use_pay_feedback: false,
      isPassword: true, // 是密码还是短信
      // 短信验证变量 ----------------------
      code: '',
      showGetCode: true, // 显示获取验证码
      countDown: 0, // 验证码倒计时
      key: '', // 验证码绑定的key
      timer: null,
      leftToPath: "",
      // ------------------------
      showAgreePop: false, // 协议弹窗
      PrivacyTimeOut: 0, // 倒计时
      PrivacyTimer: null,
      salary_pwd_count: 6, // 密码位数
      use_salary_pwd_mix: false, // 工资查询密码启用数字+字母模式，
      viewPwd: true,
      not_fix_pwd_length: false, // 不固定密码位数(勾选后表示密码位数可以小于等于指定的限制位数)
    };
  },
  beforeRouteEnter (to, from, next) {
    // console.log(from.fullPath, '1111111')
    next((vm) => {
      if (from.fullPath != "/AscLogin")      {
        vm.leftToPath = from.fullPath;
      } else      {
        location.href = localStorage.url;
      }
    });
  },
  watch: {
    value (value) {
      if (!this.use_salary_pwd_mix)      {
        if (value.length == 6)        {
          this.getData(value);
        }
      }
    },
  },
  computed: {
    iphone () {
      let tel = this.userInfo.mobilephone
      let reg = /(\d{3})\d{4}(\d{4})/
      return tel.replace(reg, "$1****$2")
    },
    canSave () {
      return this.code
    }
  },
  created () {
    this.getSalarySetting()
  },
  methods: {
    checkVal () {
      if (this.not_fix_pwd_length)      {
        this.getData(this.value);
      } else      {
        if (this.value.length == this.salary_pwd_count)        {
          this.getData(this.value);
        } else        {
          Toast.fail('请输入正确位数密码！')
        }
      }
    },
    // 同意
    agree () {
      if (this.PrivacyTimeOut > 0)
      {
        return
      } else
      {
        agreePaySignProtocol({
          empcode: this.userInfo.empcode
        }).then(res => {
          if (res.data[0].info)          {
            Notify({ type: 'danger', message: res.data[0].info })
          } else          {
            this.showAgreePop = false
          }
        })
      }
    },
    // 不同意
    notAgree () {
      this.onClickLeft()
    },
    // 隐私弹窗同意倒计时
    getTimeCount () {
      const TIME_COUNT = this.pay_agreement_timeout
      if (!this.PrivacyTimer)
      {
        this.PrivacyTimeOut = TIME_COUNT
        this.PrivacyTimer = setInterval(() => {
          if (this.PrivacyTimeOut > 0 && this.PrivacyTimeOut <= TIME_COUNT)
          {
            this.PrivacyTimeOut--
          } else
          {
            clearInterval(this.PrivacyTimer)
            this.PrivacyTimer = null
          }
        }, 1000)
      }
    },
    // 获取薪资参数
    getSalarySetting () {
      getSalarySignParam({
        empcode: this.userInfo.empcode
      }).then(res => {
        this.checkmode = res.data[0].checkmode
        this.firstchangepwd = res.data[0].firstchangepwd
        this.isfirstlogin = res.data[0].isfirstlogin
        this.nosign = res.data[0].nosign
        this.isagree = res.data[0].isagree
        this.pay_agreement_timeout = res.data[0].pay_agreement_timeout
        this.pay_agreement_url = res.data[0].pay_agreement_url
        this.use_pay_agreement = res.data[0].use_pay_agreement
        this.use_pay_feedback = res.data[0].use_pay_feedback

        this.salary_pwd_count = res.data[0].salary_pwd_count // 密码位数
        this.use_salary_pwd_mix = res.data[0].use_salary_pwd_mix // 工资查询密码启用数字+字母模式，
        this.not_fix_pwd_length = res.data[0].not_fix_pwd_length

        if (this.checkmode == 1)        {
          // 密码验证
          this.isPassword = true
        } else        {
          // 短信验证
          this.isPassword = false
        }
        if (this.use_pay_agreement == true && this.isagree == false)        {
          this.showAgreePop = true
          this.getTimeCount()
        }
      })
    },
    onClickLeft () {
      if (this.leftToPath)      {
        this.$router.push("/home");
        // this.$router.back()
      } else      {
        // 外站进入的地址
        const url = localStorage.getItem("url");
      }
    },
    onClickRight () { },
    getData (val) {
      checkSalaryPwd({
        empid: this.userInfo.empid,
        salarypwd: val,
      }).then((res) => {
        console.log(res);
        if (res.data[0].isok)        {
          // if ((this.isfirstlogin == true || this.isfirstlogin == 'true') && (this.firstchangepwd == true || this.firstchangepwd == 'true'))          {
          //   // 如果是首次打开并且 首次打开要强制修改密码 就去改密码界面
          //   this.$router.push({
          //     path: "/changeSalaryPsw",
          //     query: { from: 'checkSalaryPwd', firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign }
          //   });
          // } else          {
          this.$router.push({ path: "/salarySiginList", query: { firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign, use_pay_feedback: this.use_pay_feedback } });
          // }
        } else        {
          this.errorInfo = this.$t("salarySiginPassword.sspTxt3");
          Toast.fail(this.$t("salarySiginPassword.sspTxt3"));
        }
      });
    },

    // 发送验证码
    getCode () {
      getSmsCode({
        mobilephone: this.userInfo.mobilephone
      }).then(res => {
        if (res.iserror == 0)
        {
          Notify({ type: 'success', message: this.$t("salarySiginPassword.sspTxt13") })
          this.code = ''
          this.key = res.topvalue
          if (res.topvalue == 1234)
          {
            this.code = 1234
          }
        } else if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: this.$t("salarySiginPassword.sspTxt14") + res.errormsg
          })
        }
      })
      this.countDown = 59
      this.timer = setInterval(() => {
        if (this.countDown > 0)
        {
          this.countDown--
        } else
        {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 确定
    submit () {
      if (this.code = this.key)      {
        this.$router.push({ path: "/salarySiginList", query: { firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign, use_pay_feedback: this.use_pay_feedback } });
      } else      {
        Notify({ type: 'danger', message: this.$t("salarySiginPassword.sspTxt15") })
      }

    },
  },
};
</script>
<style lang="less" scoped>
.salaryAgreePop {
  height: 80%;
  width: 90%;
  .title {
    // color: #fff;
    height: 90px;
    padding: 20px 0;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .content {
    height: calc(100% - 200px);
  }
  .footer {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 32px;
    display: flex;
    border-top: 1px solid #ebedf0;
    .arg1 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #247dff;
      line-height: 100px;
      border-right: 1px solid #ebedf0;
    }
    .arg2 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ee0a24;
      line-height: 100px;
    }
  }
}

.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    .inputPassword {
      width: 100%;
      text-align: center;
      font-size: 36px;
      margin-top: 180px;
      margin-bottom: 78px;
    }
    .inputPasswordDesc {
      font-size: 32px;
      margin: 1.04rem 0.42667rem 0 0.42667rem;
      color: red;
    }
  }
}

.smsVerification_dia {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    .inputPassword {
      width: 100%;
      text-align: center;
      font-size: 36px;
      margin-top: 180px;
      margin-bottom: 78px;
    }
  }
}
</style>